import axios from '@axios'

export default {
  namespaced: true,
  state: {
    
  },
  getters: {},
  mutations: {},
  actions: {
    fetchTemplates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/templates', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    assignTemplates(ctx,{templates, destinationOrganization}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/templates/assign', {templates, destinationOrganization})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTemplates(ctx,{templates}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/templates/deleteTemplates', {templates})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
