import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEntities(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/entities', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    deleteEntities(ctx, ids) {
      return new Promise((resolve, reject) => {
        axios
          .post('/entities/deleteEntities', ids)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
  },
}
