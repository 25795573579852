export default [

  // *===============================================---*
  // *--------- Templates ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/template/editor',
    name: 'apps-template-editor',
    component: () => import('@/views/apps/template/template-editor/TemplateEditor.vue')
  },
  {
    path: '/apps/template/view/:templateId',
    name: 'template-to-edit',
    component: () => import('@/views/apps/template/template-editor/TemplateEditor.vue')
  },

  {
    path: '/apps/template/list',
    name: 'apps-template-editor-list',
    component: () => import('@/views/apps/template/template-list/TemplateList.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',

    },
  },

  {
    path: '/apps/entity/list',
    name: 'apps-entity-list',
    component: () => import('@/views/apps/entity/entity-list/EntityList.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',

    },
  },
  {
    path: '/apps/templateTypes/list',
    name: 'apps-template-types-list',
    component: () => import('@/views/apps/template/template-type/TemplateTypeList.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',

    },
  },
  // Debug
  {
    path: '/apps/debug/list',
    name: 'apps-debug-list',
    component: () => import('@/views/apps/debug/debug-list/DebugList.vue'),
  
  },


  // UserReport
  {
    path: '/apps/user-report/list',
    name: 'apps-user-reports-list',
    component: () => import('@/views/apps/report/user-report/user-report-list/UserReportList.vue'),
  
  },
  {
    path: '/apps/user-report-preview/:id',
    name: 'apps-user-reports-preview',
    component: () => import('@/views/apps/report/user-report/user-report-preview/UserReportPreview.vue'),
  
  },

  {
    path: '/apps/visualTemplate/list',
    name: 'apps-visual-template-list',
    component: () => import('@/views/apps/template/visual-template/VisualTemplateList.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',

    },
  },
   // *===============================================---*
  // *--------- TOKENS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/tokens/list',
    name: 'apps-tokens-list',
    component: () => import('@/views/apps/token/tokens-list/TokensList.vue'),
  },
  {
    path: '/apps/tokens/edit/:id',
    name: 'apps-tokens-edit',
    component: () => import('@/views/apps/token/tokens-edit/TokensEdit.vue'),
  },
   // *===============================================---*
  // *--------- ORGANIZATION ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/organizations/list',
    name: 'apps-organizations-list',
    component: () => import('@/views/apps/organization/organizations-list/OrganizationsList.vue'),
  },
  {
    path: '/apps/organizations/view/:id',
    name: 'apps-organizations-view',
    component: () => import('@/views/apps/organization/organizations-view/OrganizationsView.vue'),
  },
  {
    path: '/apps/organizations/edit/:id',
    name: 'apps-organizations-edit',
    component: () => import('@/views/apps/organization/organizations-edit/OrganizationsEdit.vue'),
  },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },


]
