import Vue from 'vue'
import Vuex from 'vuex'
import templateStoreModule from '@/views/apps/template/templateStoreModule'
import entityStoreModule from '@/views/apps/entity/entityStoreModule'
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-template': templateStoreModule,
    'app-entity': entityStoreModule,
  },
  strict: process.env.DEV,
})
