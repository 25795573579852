import Vue from 'vue'
import pako from 'pako';
// axios
import axios from 'axios'
const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  transformRequest: axios.defaults.transformRequest.concat(
    function (data, headers) {
 
      // compress strings if over 1KB
      if (headers['Content-Encoding'] == 'gzip' && typeof data === 'string') {
        return pako.gzip(data);
      } else {
        delete headers['Content-Encoding'];
        // delete is slow apparently, faster to set to undefined
        return data;
      }
    }
  )
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})


Vue.prototype.$http = axiosIns

export default axiosIns
